
  <template>
  <v-row justify="center">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
    <v-col cols="12" sm="12" md="12" lg="10">
      <v-card ref="form">
        <v-card-text>
          <h2 v-if="getRoleName()!= ''" style="font-weight: 300 !important;font-size:18px">Role: {{getRoleName()}}</h2>
          <v-card-title>
            <span class="pl-0 mb-5 mt-2">Sector/ Category Permissions</span>
          </v-card-title>
          <!-- <v-treeview
            selectable
            open-all
            :open="filteredKeys"
            selection-type="leaf"
            v-model="selection"
            item-key="id"
            dense
            :items="items"
          ></v-treeview> -->
          <div v-if="items.length">
                <div v-for="(sector) in items" :key="sector.id">
            <div v-if="sector.children.length">
              <li><input type="checkbox" :checked="checkIfInSelected(sector.id)" @click="selectThisSector($event, sector.id, sector.id, 'sector', sector.name)"> {{sector.name}}</li>
              <div v-for="(category) in sector.children" :key="category.id">
                  <div v-if="category.children.length">
                    <li class="pl-5"><input type="checkbox" :checked="checkIfInSelected(category.id)" @click="selectThisSector($event, category.id, sector.id, 'cat')"> {{category.name}}</li>
                        <div v-for="(subcat) in category.children" :key="subcat.id">
                              <div v-if="subcat.children.length">
                                <li class="pl-10"><input type="checkbox" :checked="checkIfInSelected(subcat.id)" @click="selectThisSector($event, subcat.id, sector.id, 'scat')"> {{subcat.name}}</li>
                                    <div v-for="(sscat) in subcat.children" :key="sscat.id">
                                        <li class="pl-15"><input type="checkbox" :checked="checkIfInSelected(sscat.id)" @click="selectThisSector($event, sscat.id, sector.id, 'sscat')"> {{sscat.name}}</li>
                                    </div>
                              </div>
                              <div v-else>
                                  <li class="pl-10"><input type="checkbox" :checked="checkIfInSelected(subcat.id)" @click="selectThisSector($event, subcat.id, sector.id, 'scat')"> {{subcat.name}}</li>
                              </div>
                        </div>
                  </div>
                  <div v-else>
                    <li class="pl-5"><input type="checkbox" :checked="checkIfInSelected(category.id)" @click="selectThisSector($event, category.id, sector.id, 'cat')"> {{category.name}}</li>
                  </div>
              </div>
            </div>
            <div v-else>
              <li><input type="checkbox" :checked="checkIfInSelected(sector.id)" @click="selectThisSector($event, sector.id, sector.id, 'sector', sector.name)"> {{sector.name}}</li>
            </div>
          </div>
          </div>
          <v-row justify="center" class="text-center mt-2 mb-2" v-if="items.length">
            <v-btn
            depressed
            color="primary"
            @click="submitTaxonomy"
          >
            Submit
          </v-btn>
          <v-btn depressed color="default" @click="$router.push({ path: './../manage-role' });">
                Cancel
          </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
</template>




<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      valid: true,
      checkbox: false,
      isLoading: true,
      fullPage: true,
      items: [],
      roles:[],
      selection: [],
      allParentNodes : false,
    };
  },
  mounted() {
    // this.get_all_taxonomy_sectors().then(this.get_product_taxonomy_data());
  },
  methods: {
    selectThisSector(e, sectorID, parentSectorID, type, sectorName='') {
      if (e.target.checked) {
          this.items.forEach((sector)=>{
            if(sector.id == parentSectorID){ // if it is sector
              //self push
              this.checkandpush(parentSectorID)
              if(type == 'sector'){
                if(sector.children.length){
                  if(confirm('Do you want to select all children of '+ sectorName+ '?')){
                      sector.children.forEach((cat)=>{
                      if(cat.children.length){
                        this.checkandpush(cat.id)
                        cat.children.forEach((subcat)=>{
                          if(subcat.children.length){
                            this.checkandpush(subcat.id)
                              subcat.children.forEach((sscat)=>{
                                this.checkandpush(sscat.id)
                              })
                          }else{
                            this.checkandpush(subcat.id)
                          }
                        })
                      }else{
                        this.checkandpush(cat.id)
                      }
                    })
                  }else{
                    e.target.checked = false
                    this.checkandremove(parentSectorID)
                  }
              }else{
                e.target.checked = true
                this.checkandpush(parentSectorID)
              }
              }else if (type == 'cat'){
                    if(sector.children.length){
                  sector.children.forEach((cat)=>{
                    if(cat.id == sectorID){
                      if(cat.children.length){
                      if(!this.selection.includes(cat.id) && cat.id == sectorID){
                        this.checkandpush(cat.id)
                        this.checkandpush(parentSectorID)
                      }
                      cat.children.forEach((subcat)=>{
                        if(subcat.children.length){
                          this.checkandpush(subcat.id)
                            subcat.children.forEach((sscat)=>{
                              this.checkandpush(sscat.id)
                            })
                        }else{
                          this.checkandpush(subcat.id)
                        }
                      })
                    }else{
                          this.checkandpush(cat.id)
                          this.checkandpush(parentSectorID)
                    }
                    }
                  })
                }
              }else if (type == 'scat'){
                  if(sector.children.length){
                  sector.children.forEach((cat)=>{
                    cat.children.forEach((subcat)=>{
                      if(subcat.id == sectorID){
                          if(subcat.children.length){
                          if(!this.selection.includes(subcat.id) && subcat.id == sectorID){
                              this.checkandpush(subcat.id)
                              this.checkandpush(cat.id)
                              this.checkandpush(parentSectorID)
                            }
                            subcat.children.forEach((sscat)=>{
                              this.checkandpush(sscat.id)
                            })
                        }else{
                            if(!this.selection.includes(subcat.id) && subcat.id == sectorID){
                              this.checkandpush(subcat.id)
                              this.checkandpush(cat.id)
                              this.checkandpush(parentSectorID)
                            }
                        }
                      }
                      })
                  })
                }
              }else{
                  if(sector.children.length){
                  sector.children.forEach((cat)=>{
                    cat.children.forEach((subcat)=>{
                        subcat.children.forEach((sscat)=>{
                                if(!this.selection.includes(sscat.id) && sscat.id == sectorID){
                                  this.checkandpush(sscat.id)
                                  this.checkandpush(subcat.id)
                                  this.checkandpush(cat.id)
                                  this.checkandpush(parentSectorID)
                                }
                            })
                      })
                  })
                }
              }
            }
          })
      } else {
        let removableItems = []
        // let filteredArray = this.selection.filter((value, index)=>{return value != sectorID})
        // this.selection = filteredArray
        this.items.forEach((sector)=>{
            if(sector.id == parentSectorID){ // if it is sector
              if(type == 'sector'){
                    if(sector.children.length){
                      if(confirm('Do you want to remove all children of '+sectorName+ '?')){
                        removableItems.push(parentSectorID)
                          sector.children.forEach((cat)=>{
                          if(cat.children.length){
                              removableItems.push(cat.id)
                                    cat.children.forEach((subcat)=>{
                                      if(subcat.children.length){
                                        removableItems.push(subcat.id)
                                          subcat.children.forEach((sscat)=>{
                                            removableItems.push(sscat.id)
                                          })
                                      }else{
                                        removableItems.push(subcat.id)
                                      }
                                    })
                          }else{
                            removableItems.push(cat.id)
                          }
                      })
                      }else{
                          e.target.checked = false
                          this.checkandremove(parentSectorID)
                          removableItems.push(parentSectorID)
                      }
                }else{
                  e.target.checked = false
                  this.checkandpush(parentSectorID)
                  removableItems.push(parentSectorID)
                }
              }else if (type == 'cat'){
                    if(sector.children.length){
                  sector.children.forEach((cat)=>{
                    if(cat.id == sectorID){
                      if(cat.children.length){
                        if(this.selection.includes(cat.id) && cat.id == sectorID){
                                removableItems.push(cat.id)
                                console.log(this.checkSiblings(cat.id,'cat',parentSectorID));
                                if(this.checkSiblings(cat.id,'cat',parentSectorID)){
                                    removableItems.push(parentSectorID)
                                  }
                              }
                              cat.children.forEach((subcat)=>{
                                if(subcat.children.length){
                                  removableItems.push(subcat.id)
                                    subcat.children.forEach((sscat)=>{
                                      removableItems.push(sscat.id)
                                    })
                                }else{
                                  removableItems.push(subcat.id)
                                }
                              })
                    }else{
                          removableItems.push(cat.id)
                          console.log(this.checkSiblings(cat.id,'cat',parentSectorID));
                          if(this.checkSiblings(cat.id,'cat',parentSectorID)){
                            removableItems.push(parentSectorID)
                          }
                    }
                    }
                  })
                }
              }else if (type == 'scat'){
                  if(sector.children.length){
                  sector.children.forEach((cat)=>{
                    cat.children.forEach((subcat)=>{
                      if(subcat.id == sectorID){
                          if(subcat.children.length){
                          if(this.selection.includes(subcat.id) && subcat.id == sectorID){
                              removableItems.push(subcat.id)
                              if(this.checkSiblings(subcat.id,'scat',parentSectorID,cat.id)){
                                removableItems.push(cat.id)
                                if(this.checkSiblings(cat.id,'cat',parentSectorID)){
                                  removableItems.push(parentSectorID)
                                }
                              }
                            }
                            subcat.children.forEach((sscat)=>{
                              removableItems.push(sscat.id)
                            })
                        }else{
                            if(this.selection.includes(subcat.id) && subcat.id == sectorID){
                              removableItems.push(subcat.id)
                              if(this.checkSiblings(subcat.id,'scat',parentSectorID,cat.id)){
                                removableItems.push(cat.id)
                                if(this.checkSiblings(cat.id,'cat',parentSectorID)){
                                  removableItems.push(parentSectorID)
                                }
                              }
                            }
                        }
                      }
                      })
                  })
                }
              }else{
                  if(sector.children.length){
                  sector.children.forEach((cat)=>{
                    cat.children.forEach((subcat)=>{
                        subcat.children.forEach((sscat)=>{
                                if(this.selection.includes(sscat.id) && sscat.id == sectorID){
                                  removableItems.push(sscat.id)
                                  if(this.checkSiblings(sscat.id,'sscat',parentSectorID,cat.id,subcat.id)){
                                    removableItems.push(subcat.id)
                                    if(this.checkSiblings(subcat.id,'scat',parentSectorID,cat.id)){
                                      removableItems.push(cat.id)
                                      if(this.checkSiblings(cat.id,'cat',parentSectorID)){
                                        removableItems.push(parentSectorID)
                                      }
                                  }
                                  }
                                }
                            })
                      })
                  })
                }
              }
            }
          })
          removableItems.forEach((s)=>{
            this.checkandremove(s)
          })
      }
    },
    checkIfInSelected(sectorID){
      return this.selection.includes(sectorID)
    },
    checkandpush(sectorID){
      if(!this.selection.includes(sectorID)){
        this.selection.push(sectorID)
      }
    },
    checkandremove(sectorID){
      let filteredArray = this.selection.filter((value, index)=>{return value != sectorID})
      this.selection = filteredArray
    },
    checkSiblings(sectorID,type,parentSectorID,parentCat=0,parentSCat=0){
      let siblingsChecked = []
      this.items.forEach((sector)=>{
        if(type == 'cat'){
          if(sector.id == parentSectorID){
            sector.children.forEach((cat)=>{
            if(cat.id != sectorID && this.selection.includes(cat.id)){
              siblingsChecked.push(cat.id)
            }
          })
          }
        }else if(type == 'scat'){
          if(sector.id == parentSectorID){
              sector.children.forEach((cat)=>{
              if(cat.id == parentCat){
                cat.children.forEach((scat)=>{
                if(scat.id != sectorID && this.selection.includes(scat.id)){
                  siblingsChecked.push(scat.id)
                }
              })
              }
          })
          }
        }else if(type == 'sscat'){
          if(sector.id == parentSectorID){
            sector.children.forEach((cat)=>{
              if(cat.id == parentCat){
                cat.children.forEach((scat)=>{
                  if(scat.id == parentSCat){
                    scat.children.forEach((sscat)=>{
                        if(sscat.id != sectorID && this.selection.includes(sscat.id)){
                          siblingsChecked.push(sscat.id)
                        }
                    })
                  }
              })
              }
          })
          }
        }
      })
      if(siblingsChecked.length == 0){
        return true
      }else{
        return false
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      // this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getRoleName(){
      let id  = this.$route.params.id
      let name  = ''
      if(this.roles.length){
        this.roles.forEach((role)=>{
          if(role.value == id){
            name = role.key
          }
        })
      }
      return name
    },
    getRoles(){
      let check = this
      const path = this.$url("USERROLE");
      this.$fetch({
        requiresAuth: true,
        operation: "view",
        vueScope: check,
      })
        .get(path)
        .then((res) => {
          console.log(res);
          this.roleName = res.data.payload.roleId[0];
          if (this.roleName.length) {
            this.roleName.forEach(function (item) {
              let a = {
                key: item[1],
                value: item[0],
              };
              check.roles.push(a);
            });
            check.isRoleDdEditing = true;
            check.role_label = "Select Role"
          }
        });
    },
    async get_all_taxonomy_sectors() {
      let view = this;
      view.isLoading = true;
      await this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view }).get(`${view.$url("ALLSECTORS")}?type=sector`).then((res) => {
        console.log(res);
        view.isLoading = false;
        let response_sectors = res.data.payload;

        view.items = response_sectors
        //view.selection = [212]
        this.get_product_taxonomy_data()
      });
    },
    get_product_taxonomy_data(){
        let view = this;
      view.isLoading = true;
      let role = this.$route.params.id
      const path = this.$url("ROLE_PRODUCT_TAXONOMY") +  "?q=" + role;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view }).get(path).then((res) => {
        console.log(res);
        view.isLoading = false;
        if(res.data.payload.sectors){
          view.selection = res.data.payload.sectors;
        }
      });
    },
    submitTaxonomy(){
      let view = this;
      view.isLoading = true;
      let role = this.$route.params.id
      const path = this.$url("ROLE_PRODUCT_TAXONOMY");
      let data = {
        role : role,
        sectors_data : view.selection,
      }
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view }).put(path, data).then((res) => {
        console.log(res);
        view.isLoading = false;
        if (res.data.statusCode == 200) {
                this.$swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Product Taxonomy data updated successfully!",
                });
        }else{
          this.$swal.fire({
                  icon: "success",
                  title: "Error",
                  text: "Data could not be updated!",
                });
        }
      }).catch((error) => {
           console.error(error);
           view.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });;
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
  computed:{
    filteredKeys() {
      return this.items.map((top) => {
        return top.id
      })
    },
    // _items () {
    //    const replaceChildren = (obj,parent) => {
    //      const clone = Object.assign({},obj)
    //      delete clone.children
    //      if (parent) clone.parent = parent
    //      return clone
    //    }

    //    const addItems = (arr,parent) => {
    //      const items = arr.reduce((acc,x)=>{

    //        acc.push(replaceChildren(x,parent))

    //        if (x.children) {
    //          acc.push(addItems(x.children, x.id))
    //        }
    //        return acc
    //      },[])

    //      return items.flat()
    //    }

    //    return addItems(this.items).reduce((acc,x)=>{
    //      acc[x.id]=x
    //      return acc
    //    },{})
    // },
    // _selection () {
    //    const proxy = {}
    //    addParents = (x, all) => {
    //      const parentId = this._items[x.id].parent
    //      if (parentId) {
    //        if (all) addParents(this._items[parentId])
    //        proxy[parentId] = this._items[parentId]
    //      }
    //    }
    //    if(this.selection.length){
    //      this.selection.forEach(x=>{
    //      addParents(x,this.allParentNodes)
    //      proxy[x.id] = x
    //    })
    //    }

    //   return Object.values(proxy)
    // }
  },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
        //this.get_all_taxonomy_sectors();
        this.get_all_taxonomy_sectors();
        this.getRoles();
      }
    },
 },
};
</script>
<style  scoped lang="sass">
  $treeview-node-height-dense : 8px !default
  li
    list-style: none
    padding:1px
    font-size: 12px
</style>
